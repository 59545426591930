import {
  fetchBaseQuery,
  BaseQueryApi,
} from '@reduxjs/toolkit/query';
import getConfig from 'config';
import { getAuthorizationHeader } from 'utils';

export const prepareHeaders = (
  headers: Headers,
  api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>,
) => {
  const { getState } = api;
  const { accessToken } = (getState() as any).secretKeeper || {};
  if (accessToken) {
    headers.set('Authorization', getAuthorizationHeader(accessToken));
  }
  return headers;
};

const { NEXT_PUBLIC_SECRET_KEEPER_API, NEXT_PUBLIC_PROXY } = getConfig();
const baseUrl = NEXT_PUBLIC_PROXY?.['secret-keeper'] ? '/api/proxy-secret-keeper' : `${NEXT_PUBLIC_SECRET_KEEPER_API}/api`;

export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders,
});

export const refreshQuery = fetchBaseQuery({
  baseUrl,
  method: 'POST',
  credentials: 'include',
});
import {
  createSlice,
} from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Column, TableSettingsState } from './types';
import { Storage } from '../types';
import { getInitialState } from './helpers';
import migrations from './migrations';

const initialState: TableSettingsState = getInitialState();

export const tableSettings = createSlice({
  name: 'tableSettings',
  initialState,
  reducers: {
    updateTableColumns(state, action: PayloadAction<{ table: string, columns: Column[] | undefined }>) {
      state.columns = { ...state.columns, [action.payload.table]: action.payload.columns };
    },
    resetTableColumns(state, action: PayloadAction<string>) {
      state.columns = { ...state.columns, [action.payload]: undefined };
    },
    resetColumns(state) {
      state.columns = undefined;
    },
  },
  selectors: {
    columnsSelector: (state) => state.columns,
  },
});

export const {
  updateTableColumns,
  resetTableColumns,
  resetColumns,
} = tableSettings.actions;

export const {
  columnsSelector,
} = tableSettings.selectors;

export const getReducer = (storage: Storage) => persistReducer({
  key: 'tableSettings', storage, whitelist: ['columns'], migrate: migrations, version: 0,
}, tableSettings.reducer);

import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'connectors/spProvider';
import { baseQueryWithRefresh } from '../authOAuth2/baseQueryWithRefresh';
import {
  File, PostFileProps, Storage, PatchFileProps, PostFileResult, UserSettings, PostStorage,
} from './types';

export const api = createApi({
  reducerPath: 'filesApi',
  baseQuery: baseQueryWithRefresh(baseQuery),
  tagTypes: ['Files'],
  endpoints: (builder) => ({
    getFile: builder.query<File, string>({
      query(file) {
        return {
          url: `files/${file}`,
        };
      },
    }),
    getFiles: builder.query<File[], null>({
      query() {
        return {
          url: 'files',
        };
      },
    }),
    postFile: builder.query<PostFileResult, PostFileProps>({
      query(body) {
        return {
          url: 'files',
          method: 'POST',
          body,
        };
      },
    }),
    deleteFile: builder.query<File, string>({
      query(id) {
        return {
          url: `files/${id}`,
          method: 'DELETE',
        };
      },
    }),
    patchFile: builder.query<File, PatchFileProps>({
      query({ body, id }) {
        return {
          url: `files/${id}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    getStorages: builder.query<Storage[], null>({
      query() {
        return {
          url: 'storages',
        };
      },
    }),
    getStorage: builder.query<Storage, string>({
      query(storage) {
        return {
          url: `storages/${storage}`,
        };
      },
    }),
    createCentralizedStorage: builder.query<Storage, null>({
      query() {
        return {
          url: 'storages/centralized',
          method: 'POST',
        };
      },
    }),
    getUserSettings: builder.query<UserSettings, null>({
      query() {
        return {
          url: 'user-settings',
        };
      },
    }),
    updateUserSettings: builder.query<UserSettings, Partial<UserSettings>>({
      query(body) {
        return {
          url: 'user-settings',
          method: 'PATCH',
          body,
        };
      },
    }),
    createUserSettings: builder.query<UserSettings, Partial<UserSettings>>({
      query(body) {
        return {
          url: 'user-settings',
          method: 'POST',
          body,
        };
      },
    }),
    createStorage: builder.query<Storage, PostStorage>({
      query(body) {
        return {
          url: 'storages',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useGetFileQuery,
  useGetFilesQuery,
  useLazyPostFileQuery,
  useLazyPatchFileQuery,
  useLazyDeleteFileQuery,
  useGetStoragesQuery,
  useCreateCentralizedStorageQuery,
  useLazyCreateCentralizedStorageQuery,
  useLazyGetStoragesQuery,
  useLazyCreateUserSettingsQuery,
  useLazyGetUserSettingsQuery,
  useGetUserSettingsQuery,
  useLazyUpdateUserSettingsQuery,
  useGetStorageQuery,
  useLazyGetStorageQuery,
  useLazyCreateStorageQuery,
} = api;
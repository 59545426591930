import BigNumber from 'bignumber.js';
import {
  OptionInfo, PriceType, SlotInfo, SlotUsage, TeeOfferSlot, OfferSlot,
} from 'generated/types';
import { getMultipliedDeposit, getSumDeposit } from 'utils/sdk';
import {
  getGb, getGbit, getHoursFromMinutes, getMbit, MINUTES_IN_HOURS, round,
} from './math';

export interface OfferSlotsInfo {
  cpuCores: number;
  ram: number;
  diskUsage: number;
  maxTimeMinutes: number;
  minTimeMinutes: number;
  bandwidth: number;
  externalPort: number;
  traffic: number;
}

type Value = string | boolean | number;

type GetOfferSlotValueFormattedCb = (value?: any) => string;
type GetOfferSlotExtenstionCb = (value?: any) => string;

export const offerSlotsInfoList: (keyof OfferSlotsInfo)[] = [
  'cpuCores',
  'ram',
  'diskUsage',
  'bandwidth',
  'externalPort',
  'traffic',
  'maxTimeMinutes',
  'minTimeMinutes',
];

export const offerSlotLabelFormatters = new Map<keyof OfferSlotsInfo, string>([
  ['cpuCores', 'vCores'],
  ['ram', 'RAM'],
  ['diskUsage', 'Disk'],
  ['bandwidth', 'Bandwidth'],
  ['traffic', 'Traffic'],
  ['externalPort', 'Ext.Port'],
  ['maxTimeMinutes', 'Max Time'],
  ['minTimeMinutes', 'Min Time'],
]);

const offerSlotExtenstion = new Map<keyof OfferSlotsInfo, GetOfferSlotExtenstionCb>([
  ['ram', () => 'GB'],
  ['diskUsage', () => 'GB'],
  ['bandwidth', () => 'Mbps'],
  ['traffic', () => 'Gb'],
  ['maxTimeMinutes', (value?: number) => {
    if (!value) return '';
    return value >= MINUTES_IN_HOURS ? 'hours' : 'minutes';
  }],
  ['minTimeMinutes', (value?: number) => (!value || value >= MINUTES_IN_HOURS ? 'hours' : 'minutes')],
]);

export const getOfferSlotLabelFormatted = (key: keyof OfferSlotsInfo): string => {
  return offerSlotLabelFormatters.get(key) ?? key;
};

export const offerSlotValueFormatters = new Map<keyof OfferSlotsInfo, GetOfferSlotValueFormattedCb>([
  ['externalPort', (value?: boolean) => (value ? 'Yes' : 'No')],
  ['ram', (value?: number) => round(getGb(value))],
  ['diskUsage', (value?: number) => round(getGb(value))],
  ['traffic', (value?: number) => round(getGbit(value))],
  ['bandwidth', (value?: number) => round(getMbit(value))],
  [
    'maxTimeMinutes',
    (value?: number) => {
      if (!value) return 'Unlimited';
      return round((value >= MINUTES_IN_HOURS) ? getHoursFromMinutes(value) : value);
    },
  ],
  ['minTimeMinutes', (value?: number) => round((!value || value >= MINUTES_IN_HOURS) ? getHoursFromMinutes(value) : value)],
]);

export const getOfferSlotValueFormatted = (key: keyof OfferSlotsInfo, value?: Value): string => {
  return offerSlotValueFormatters.get(key)?.(value) ?? `${value}`;
};

export const getOfferSlotExtension = (key: keyof OfferSlotsInfo, value?: Value): string => {
  return offerSlotExtenstion.get(key)?.(value) ?? '';
};

export const getLabelWithExtenstion = (key: keyof OfferSlotsInfo, value?: Value): string => {
  const label = getOfferSlotLabelFormatted(key);
  const extension = getOfferSlotExtension(key, value);
  const extensionText = extension ? `, ${extension}` : '';
  return `${label}${extensionText}`;
};

export const priceTypeMap = {
  [PriceType.PerHour]: 'Per Hour',
  [PriceType.Fixed]: 'Fixed',
};

export const keysSlot = ['cpuCores', 'diskUsage', 'ram'];

const optionsInfo = ['bandwidth', 'traffic', 'externalPort'];

export const convertSlotInfo = (slotInfo?: SlotInfo): { label: string; value: string }[] => {
  if (!slotInfo) return [];
  const res = keysSlot.reduce((acc: { label: string; value: string }[], k) => ([
    ...acc,
    {
      label: getOfferSlotLabelFormatted(k as keyof OfferSlotsInfo),
      value: typeof slotInfo[k] === 'number'
        ? `
          ${getOfferSlotValueFormatted(k as keyof OfferSlotsInfo, slotInfo[k])}
          ${getOfferSlotExtension(k as keyof OfferSlotsInfo, slotInfo[k])}
        `
        : '-',
    },
  ]), []);
  return res;
};

export const convertOptionData = (optionInfo?: OptionInfo[]): { label: string; value: string }[] => {
  if (!optionInfo) return [];
  const optionsInfoInit = optionsInfo.reduce((acc, item) => ({
    ...acc,
    [item]: 0,
  }), {});
  const optionsSum = optionInfo.reduce((acc, item) => ({
    ...acc,
    ...Object.entries(item).reduce((accOp, [k, v]) => ({
      ...accOp,
      [k]: acc[k] + v,
    }), {}),
  }), optionsInfoInit);
  const res = Object.entries(optionsSum).reduce((acc: { label: string; value: string }[], [k, v]) => ([
    ...acc,
    {
      label: getOfferSlotLabelFormatted(k as keyof OfferSlotsInfo),
      value: v !== undefined
        // eslint-disable-next-line max-len
        ? `${getOfferSlotValueFormatted(k as keyof OfferSlotsInfo, v as number)} ${getOfferSlotExtension(k as keyof OfferSlotsInfo, v as number)}`
        : '-',
    },
  ]), []);
  return res;
};

export interface Deposit {
  perHour: BigNumber | null;
  fixed: BigNumber | null;
  perHourByLease: BigNumber | null; // multiply by lease with minTimeMinutes check
}

export interface GetDepositProps {
  slotUsage?: SlotUsage;
  count?: number;
  deposit?: Deposit;
  lease?: number;
  minTimeMinutesTEE?: number;
}

export const getDeposit = (props: GetDepositProps): Deposit => {
  const {
    slotUsage,
    count,
    deposit = { perHour: null, fixed: null, perHourByLease: null },
    lease = 0,
    minTimeMinutesTEE = 0, // does not need for options
  } = props || {};
  const newDeposit = { ...deposit };
  if (!slotUsage) return newDeposit;
  const { minTimeMinutes, priceType, price } = slotUsage || {};
  const MINUTES_IN_HOUR = 60;
  const globalMinRentMinutes = Math.max(lease * 60, minTimeMinutes || minTimeMinutesTEE) || MINUTES_IN_HOUR;
  if (priceType === PriceType.PerHour) {
    newDeposit.perHourByLease = count && price
      ? getSumDeposit(newDeposit.perHourByLease, getMultipliedDeposit(price, (count * globalMinRentMinutes) / 60))
      : newDeposit.perHour;
    newDeposit.perHour = count && price
      ? getSumDeposit(newDeposit.perHour, getMultipliedDeposit(price, count))
      : newDeposit.perHour;
  } else if (priceType === PriceType.Fixed) {
    newDeposit.fixed = count && price
      ? getSumDeposit(newDeposit.fixed, getMultipliedDeposit(price, count))
      : newDeposit.fixed;
  }
  return newDeposit;
};

export const getMinPriceFromSlots = (slots: (TeeOfferSlot | OfferSlot)[], priceType: PriceType): string | null => {
  if (!slots?.length) return null;
  if (!priceType) return null;
  return slots
    .filter(({ usage }) => usage?.priceType === priceType)
    .map(({ usage }) => usage?.price || null)
    .reduce((acc, price) => {
      if (typeof price === 'string') {
        return typeof acc === 'string' ? BigNumber.minimum(acc || '0', price).toString() : price;
      }
      return acc;
    }, null);
};
import { DisplayColumnDef } from '@tanstack/react-table';
import { Node } from 'types/pages';
import { getComputeColumns } from 'components/Main/Content/columns/helpers';
import { getMyContentColumns } from 'components/MyContent/TableContent/helpers';
import { Column } from './types';

const getInitialStateCompute = () => {
  const hidenColumns = ['offerId', 'provider', 'name', 'priceFixed', 'maxTimeMinutes'];
  return Object.values(getComputeColumns(false)).reduce((acc: Column[], item: DisplayColumnDef<Node>) => {
    const { id, header: label, meta } = item;
    return !meta?.show ? [
      ...acc,
      {
        id: id || '',
        label: (label || '') as string,
        checked: !hidenColumns.includes(id as string),
      },
    ] : acc;
  }, []);
};

const getInitialStateMyContent = () => {
  const hidenColumns = ['id'];
  return Object.values(getMyContentColumns()).reduce((acc: Column[], item: DisplayColumnDef<Node>) => {
    const { id, header: label, meta } = item;
    return !meta?.show ? [
      ...acc,
      {
        id: id || '',
        label: (label || '') as string,
        checked: !hidenColumns.includes(id as string),
      },
    ] : acc;
  }, []);
};

export const getInitialState = () => {
  return {
    columns: {
      compute: getInitialStateCompute(),
      'my-content': getInitialStateMyContent(),
    },
  };
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SpProviderState,
} from './types';
import { getInitialState } from './helpers';

const initialState: SpProviderState = getInitialState();

export const spProvider = createSlice({
  name: 'spProvider',
  initialState,
  reducers: {
    resetState: (state, action: PayloadAction<Partial<SpProviderState> | undefined>) => ({
      ...getInitialState(),
      ...action.payload,
    }),
  },
  selectors: {},
});

export const {
  resetState,
} = spProvider.actions;
import { DisplayColumnDef } from '@tanstack/react-table';
import { getOfferSlotLabelFormatted, getMinPriceFromSlots } from 'utils/slots';
import {
  TeeOffer, Offer, PriceType, OfferSlot, TeeOfferSlot,
} from 'generated/types';
import { FieldsBuildOrderForm, FormOffers, FormOffer } from 'lib/features/createOrder/types';
import { Column } from '../Table/types';

export const getComputeColumns = (isMobile: boolean): Record<string, DisplayColumnDef<Column>> => ({
  offerId: {
    id: 'offerId',
    header: 'Offer ID',
    size: 82,
  },
  name: {
    id: 'name',
    header: 'Name',
    size: 120,
  },
  provider: {
    id: 'provider',
    header: 'Provider',
    size: 120,
  },
  cpuCores: {
    id: 'cpuCores',
    header: getOfferSlotLabelFormatted('cpuCores'),
    size: 80,
  },
  ram: {
    id: 'ram',
    header: getOfferSlotLabelFormatted('ram'),
    size: 80,
  },
  diskUsage: {
    id: 'diskUsage',
    header: getOfferSlotLabelFormatted('diskUsage'),
    size: 80,
  },
  bandwidth: {
    id: 'bandwidth',
    header: getOfferSlotLabelFormatted('bandwidth'),
    size: 80,
  },
  traffic: {
    id: 'traffic',
    header: getOfferSlotLabelFormatted('traffic'),
    size: 80,
  },
  externalPort: {
    id: 'externalPort',
    header: getOfferSlotLabelFormatted('externalPort'),
    size: 80,
  },
  minTimeMinutes: {
    id: 'minTimeMinutes',
    header: getOfferSlotLabelFormatted('minTimeMinutes'),
    size: 80,
  },
  maxTimeMinutes: {
    id: 'maxTimeMinutes',
    header: getOfferSlotLabelFormatted('maxTimeMinutes'),
    size: 80,
  },
  pricePerHour: {
    id: 'pricePerHour',
    header: 'Price, hr',
    size: 96,
  },
  deposit: {
    id: 'deposit',
    header: 'Deposit',
    size: 80,
  },
  ordersInQueue: {
    id: 'ordersInQueue',
    header: 'In queue',
    size: 80,
  },
  icon: {
    id: 'icon',
    header: '',
    meta: {
      show: !isMobile,
    },
    size: 100,
  },
});

export const getSelectedSlot = (
  formOffer?: FormOffer,
  offer?: Offer | TeeOffer | null,
): OfferSlot | TeeOfferSlot | null => {
  if (!formOffer || !offer) return null;
  if (formOffer.value === offer.id && offer.slots?.length) {
    return (offer.slots as (OfferSlot | TeeOfferSlot | null)[]).find((slot) => slot?.id === formOffer.slots?.slot?.id) || null;
  }
  return null;
};

export const getSlotPrice = (
  offer?: Offer | TeeOffer | null,
  priceType?: PriceType,
  formOffers?: FormOffers,
  field?: FieldsBuildOrderForm,
): null | string => {
  if (!offer?.slots || !priceType || !formOffers || !field) return null;
  const formOffersByField = formOffers[field];
  const selectedSlot = Array.isArray(formOffersByField)
    ? formOffersByField
      .map((formOffer) => getSelectedSlot(formOffer, offer))
      .find((value) => value)
    : getSelectedSlot(formOffersByField, offer);
  if (selectedSlot) {
    return selectedSlot.usage?.priceType === priceType ? selectedSlot.usage?.price : null;
  }
  return getMinPriceFromSlots(offer?.slots, priceType);
};
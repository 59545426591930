import { EncryptionWithMacIV, Hash, StorageType } from '@super-protocol/dto-js';

export enum Type {
  model = 'model',
  dataset = 'dataset',
}

export interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  address?: string;
  nonce?: string;
}

export enum Providers {
  metamask = 'metamask',
}

export interface AccessToken { address?: string; provider?: Providers; id?: string }

export interface SecretKeeperState {
  user?: User | null;
  accessToken?: string | null;
  loadingAuthWallet: boolean;
}

export interface TokenResponse { user: User, accessToken: string }

export interface PostFileProps {
  path: string;
  storageId: string;
  name: string;
  description: string;
  type: Type;
  hash: Hash;
  encryption: EncryptionWithMacIV;
  size: string;
}

export interface PatchFileBodyProps {
  name: string;
  description: string;
  type: Type;
}
export interface PostFileResult {
  id: string;
}

export interface PatchFileProps {
  body: PatchFileBodyProps;
  id: string;
}

export interface StorjStorageCredentials {
  readAccessToken: string;
  writeAccessToken: string;
}

export interface S3StorageCredentials {
  region?: string;
  writeAccessKeyId: string;
  writeSecretAccessKey: string;
  readAccessKeyId: string;
  readSecretAccessKey: string;
}

export interface File {
  id: string;
  storageId: string;
  userId: string;
  path: string;
  hash: Hash;
  encryption: EncryptionWithMacIV;
  name: string;
  description: string;
  type: Type;
  size: number;
  createdAt: string;
  updatedAt: string;
}

export interface Storage {
  id: string;
  isCentralized: boolean;
  userId: string;
  storageType: StorageType;
  bucket: string;
  prefix: string;
  storjCredentials?: StorjStorageCredentials;
  s3Credentials?: S3StorageCredentials;
  createdAt: string;
  updatedAt: string;
}

export interface UserSettings {
  activeStorageId?: string;
  decentralizedStorageId?: string;
}

export type PostStorage = Omit<Storage, 'id' | 'updatedAt' | 'createdAt' | 'isCentralized' | 'userId'>;